
const bookingInfo = [
    {
      displayName: 'Russel Raelly',
      id: 'raellyr',
      default: 15,
    },
    {
      displayName: 'Babalwa Dlongwana',
      id: 'dlongwanab',
      default: 30,
    },
    {
      displayName: 'Andrea Carolus',
      id: 'carolusa',
      default: 30,
    },
    {
      displayName: 'Dewald van Schalkwyk',
      id: 'niemandth',
      default: 15,
    },
    {
      displayName: 'Craig Felaar',
      id: 'langfordg',
      default: 30,
    },
    {
      displayName: 'Mlondolozi Mafuya',
      id: 'mafuyam',
      default: 15,
    },
    {
      displayName: 'Lungile Vatyana',
      id: 'vatyanal',
      default: 15,
    },
    {
      displayName: 'Leslie Hambury',
      id: 'hamburyl',
      default: 15,
    },
    {
      displayName: 'Ncumisa Nyila',
      id: 'nyilan',
      default: 30,
    },
    {
      displayName: 'Noluthando Mantanga',
      id: 'mantangan63',
      default: 30,
    },
    {
      displayName: 'Marvin Fonley',
      id: 'fonleym',
      default: 15,
    },
    {
      displayName: 'Sindiswa Ntozini',
      id: 'Ntozinis',
      default: 30,
    },
    {
      displayName: 'Yolanda Maxengana',
      id: 'maxenganay',
      default: 30,
    },
    {
      displayName: 'Nocawa Ngamlana',
      id: 'ngamlanan',
      default: 30,
    },
    {
      displayName: 'Akhona Njingolo',
      id: 'njingoloa',
      default: 30,
    },
    {
      displayName: 'Luthando Kapoyi',
      id: 'kapoyil',
      default: 30,
    },
    {
      displayName: 'Bulie Nyamakazi',
      id: 'bulie_23',
      default: 30,
    },
    {
      displayName: 'Roman Robertson',
      id: 'robertsonr-zsa',
      default: 30,
    },
    {
      displayName: 'Mitchell Masitise',
      id: 'masitisem',
      default: 30,
    },
  ];
  
export const OFFICERS_INFO = bookingInfo.sort(function (a, b) {
    if (a.displayName < b.displayName) {
      return -1;
    }
    if (a.displayName > b.displayName) {
      return 1;
    }
    return 0;
  });